<template>
  <div class="create-new-mapping-root">
    <MappingContainer
      :title="`Newly created ${mappingTypeLabel}`"
      :subtitle="`Event created from ${feedLabelCapitalized} database`"
    >
      <MappingItem
        :feed="selectedFeed === 'huddle' ? data.details.icon : 'huddle'"
        :title="data.details.name"
        :subtitle="data.details.additionalInfo"
      />
      <div class="create-new-mapping__instances">
        <MappedItem
          v-for="subMapping in data.subMappings"
          :key="subMapping.type"
          :title="subMapping.title"
          :mapping-type="subMapping.mapped ? 'EXACT_MATCH' : 'NO_MATCH'"
          :subtitle="subMapping.data.extRef"
          :actionable="!subMapping.mapped"
          @click="subMappingMap(subMapping)"
        />
      </div>
    </MappingContainer>
    <div
      v-if="!approveMappingDisabled"
      class="approve-mapping-root"
    >
      <Button
        variant="primary"
        @click="approveMapping"
      >
        Done
      </Button>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { find, assign } from 'lodash';
import {
  getLabelFromType,
} from '@/services/helpers/mappings-mapper';
import Button from '@/components/common/Button';
import MappingContainer from '@/components/mapping/common/MappingContainer';
import MappingItem from '@/components/mapping/common/MappingItem';
import MappedItem from '@/components/mapping/common/MappedItem';

export default {
  components: {
    Button,
    MappingContainer,
    MappingItem,
    MappedItem,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    selectedFeed: {
      type: Object,
      required: true,
      default: () => {},
    },
    mappingType: {
      type: String,
      required: true,
      default: () => '',
    },
    rowMappingId: {
      type: String,
      required: true,
      default: () => '',
    },
  },
  emits: ['subMappingActive', 'mappingCreated'],
  setup(props, { emit }) {
    const feedLabelCapitalized = computed(() => props.selectedFeed.feed[0].toUpperCase() + props.selectedFeed.feed.slice(1));
    const mappingTypeLabel = computed(() => getLabelFromType(props.mappingType));
    const approveMappingDisabled = computed(() => {
      if (find(props.data.subMappings, { mapped: false, required: true })) {
        return true;
      }
      return false;
    });

    const approveMapping = () => {
      emit('mappingCreated');
    };

    const subMappingMap = (subMapping) => {
      const data = assign({ ...subMapping.data }, { mappingType: subMapping.mappingType });
      emit('subMappingActive', { data, type: subMapping.type });
    };
    return {
      feedLabelCapitalized,
      mappingTypeLabel,
      approveMappingDisabled,
      approveMapping,
      subMappingMap,
    };
  },
};
</script>

<style lang="scss">
.create-new-mapping-root {
    width: 100%;
    padding-top: 20px;
    font-family: 'Rubik';

    .break-line {
        width: 100%;
        height: 1px;
        background-color: $gray400;
    }
    h1 {
        font-family: 'Poppins';
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: $black;
    }
    .subtitle {
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: $gray700;
        margin-bottom: 16px;
    }

    .mapping-root {
        padding: 30px 0;
        .header {
          padding-left: 8px;
          .name {
              font-size: 14px;
              font-weight: 400;
              line-height: 17px;
              color: $black;
          }
          .metadata {
              font-size: 12px;
              font-weight: 400;
              line-height: 14px;
              color: $gray700;
          }
        }

        .sub-mappings-root {
            padding-top: 15px;
            .sub-mapping-item {
                padding: 4px 12px;
                display: flex;
                border-radius: 4px;
                align-items: center;

                &:hover {
                    background-color: $gray300;
                }

                &.not-mapped:hover {
                    .action-root {
                        display: inline-block;
                    }
                }

                .icon-root {
                    height: 100%;
                    display: inline-block;
                    padding: 15px 13px;
                    background-color: #e5f8ee;
                    border-radius: 4px;

                    svg {
                        fill: $success500;
                    }

                }
                &.not-mapped .icon-root {
                    background-color: $error50;
                    svg {
                        fill: $error500;
                    }
                }
                .data-root {
                    width: 80%;
                    padding-left: 12px;
                    .title {
                        font-size: 10px;
                        font-weight: 600;
                        line-height: 12px;
                        text-transform: uppercase;
                        margin-bottom: 4px;
                    }
                    .name {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 17px;
                    }
                }

                .action-root {
                    display: none;
                    height: 33px;

                    button {
                        height: 100%;
                        display: flex;
                        justify-content: space-between;

                        .icon {
                            margin: 0 0 0 5px;
                            svg {
                                fill: $black;
                            }
                        }
                    }
                }
            }
        }
    }

    .approve-mapping-root {
        padding-top: 32px;
        display: flex;
        justify-content: flex-end;
    }
}
</style>
