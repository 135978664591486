<template>
  <div class="previous-matches-wrapper">
    <div class="history-header">
      <div class="history-header-label">
        History overview
      </div>
      <div
        class="history-header-close-button"
        @click="closeHistory"
      >
        <Icon :name="'x'" />
      </div>
    </div>
    <div
      class="no-history"
      v-if="!teams?.length"
    >
      No history data
    </div>
    <div
      class="history-team-wrapper"
      v-for="(team, index) in teams"
      :key="index"
    >
      <div class="history-team-name">
        {{ team.teamName }}
      </div>
      <div
        class="team-opponents"
        v-if="team.previousOpponents?.length"
      >
        <div class="team-opponents-header">
          <div>
            {{ team.playerMapping ? 'TEAMS' : 'OPPONENTS' }}
          </div>
          <div>DATE</div>
        </div>
        <div
          class="team-opponent"
          v-for="(opponent, idx) in team.previousOpponents"
          :key="idx"
        >
          <div class="team-opponent-name">
            {{ team.playerMapping ? opponent.competitorName : `vs ${opponent.competitorName}` }}
          </div>
          <div class="team-opponent-details">
            <div class="team-opponent-competition">
              {{ opponent.competitionName }}
            </div>
            <div class="team-opponent-date">
              {{ displayDate(opponent.startsAtUtc) }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="no-history opponent"
        v-else
      >
        No history data
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon';
import formatDate, { DateTypes } from '@/utils/format-date';

export default {
  components: {
    Icon,
  },
  props: {
    teams: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'closeHistory',
  ],
  setup(props, { emit }) {
    const closeHistory = () => {
      emit('closeHistory');
    };
    const displayDate = (time) => formatDate(time, DateTypes.DATE);

    return {
      closeHistory,
      displayDate,
    };
  },
};
</script>
<style lang="scss">

.previous-matches-wrapper {
  position: absolute;
  width: 364px;
  min-height: 110px;
  background-color: #fff;
  border-radius: 4px;
  z-index: 102;
  left: 725px;
  top: 0;
  padding: 12px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .history-header {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    box-sizing: border-box;

    .history-header-label {
      font-size: 16px;
      color: #000;
    }

    .history-header-close-button {
      cursor: pointer;
      .icon {
        width: 24px;
        height: 24px;

        svg {
          stroke: #191414;
        }
      }
    }
  }

  .history-team-wrapper {
    width: 100%;
    padding: 0 8px;
    box-sizing: border-box;

    &:last-child {
      padding-bottom: 24px;
    }

    .history-team-name {
      height: 35px;
      line-height: 35px;
      width: 100%;
      font-size: 16px;
      font-weight: 600;
      color: #000;
      box-shadow: inset 0px -1px 0px #CDCDCD;
      margin-bottom: 4px;
    }

    .team-opponents {
      .team-opponents-header {
        width: 100%;
        height: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: #A9A9A9;
        margin-bottom: 8px;
        padding-right: 38px;
      }

      .team-opponent {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 8px;

        .team-opponent-name {
          height: 17px;
          line-height: 17px;
          font-size: 14px;
          color: #191414;
        }

        .team-opponent-details {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 14px;
          align-items: center;
          font-size: 12px;
          color: #191414;

          .team-opponent-competition {
            color: #A9A9A9;
          }
        }
      }
    }
  }
}

.no-history {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #6D6D6D;
  justify-content: center;

  &.opponent {
    justify-content: flex-start;
  }
}

</style>
