<template>
  <div
    ref="parentRef"
    :class="['suggested-mappings-root', {
      'single-mapping position-unset': suggestedMappingsLength === 1,
      'last-in-list': suggestedMappingsLength > 1 &&
        index === (suggestedMappingsLength - 1)
    }]"
    @mouseenter="setIsMappingHovered(true)"
    @mouseleave="setIsMappingHovered(false)"
  >
    <div class="suggested-mappings-details">
      <ProgressChart :similarity="mapping.similarity" />
      <div class="details-info">
        <div class="details-info-name">
          {{ mapping.title }}
        </div>
        <div class="details-info-date-id">
          {{ mapping.subtitle }}
        </div>
      </div>
    </div>
    <div
      v-if="isMappingHovered"
      class="suggested-mappings-actions"
    >
      <div
        class="mapping-button approve"
        @click="approveMapping"
      >
        Map this result
      </div>
      <Dropdown
        @menuToggled="setLastItemMenuPosition"
        class="more-actions-btn"
        icon="more-dots"
        :menu-top-value="menuTopPosition"
        hide-chevron
        right
        transparent-button
      >
        <DropdownItem
          v-for="action in mapping.actions"
          :key="action.actionType"
          @click="handleAdditionalActions(action.actionType)"
          clickable
        >
          {{ action.label }}
        </DropdownItem>
      </Dropdown>
    </div>
    <div
      :class="['copy-confirmation ', { 'active': copyIdMessageActive }]"
    >
      ID copied to the clipboard
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import {
  map, reverse, assign,
} from 'lodash';
import ProgressChart from '@/components/common/ProgressChart';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';
import {
  createMapping,
  mappingActionTypes,
  copyToClipboard,
  mappingTypes,
  feedTypes,
} from '@/services/helpers/mappings-mapper';

export default {
  components: {
    ProgressChart,
    Dropdown,
    DropdownItem,
  },
  props: {
    suggestedMapping: {
      required: true,
      type: Object,
      default: () => ({}),
    },
    editData: {
      required: true,
      type: Object,
      default: () => ({}),
    },
    mappingType: {
      required: true,
      type: String,
    },
    selectedSportLabel: {
      required: true,
      type: String,
      default: () => '',
    },
    flippedEvents: {
      required: true,
      type: Boolean,
    },
    suggestedMappingsLength: {
      required: true,
      type: Number,
    },
    index: {
      required: true,
      type: Number,
    },
    selectedFeed: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    subMappingActive: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    subMappingMappingId: {
      required: false,
      type: String,
      default: () => '',
    },
    isEventList: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['viewHistory', 'flipEvents', 'closePopup', 'subMappingMapped', 'mappingApproved'],
  setup(props, { emit }) {
    const store = useStore();
    const parentRef = ref(false);
    const isMappingHovered = ref(false);
    const copyIdMessageActive = ref(false);
    const menuTopPosition = ref(0);

    const {
      VIEW_HISTORY,
      COPY_ID,
      SWITCH_TEAMS,
    } = mappingActionTypes;
    const { HUDDLE } = feedTypes;

    const setIsMappingHovered = (isHovered) => { isMappingHovered.value = isHovered; };
    const mapping = computed(() => createMapping({ props, flippedEvents: props.flippedEvents, isEventList: props.isEventList }));
    const mappingForEventList = computed(() => store.getters.eventListMappingEvent);

    const showNotificationMessage = (mappingFor) => {
      const message = `You have successfuly created new mapping for ‘${mappingFor}’.`;
      store.dispatch('addNotification', {
        message,
        type: 'success',
        duration: 5000,
      });
    };

    const setLastItemMenuPosition = () => {
      if (parentRef.value.classList.value.includes('last-in-list')) {
        menuTopPosition.value = parentRef.value.offsetHeight;
      }
    };

    const approveCompetitorMapping = () => {
      const competitor = props.suggestedMapping;
      const mappingRequest = {
        competitorId: competitor.competitorId,
        mappingId: props.selectedFeed.feed === HUDDLE ? competitor.mapping.mappingId : props.editData.row.mappingId,
        sport: props.selectedSportLabel,
      };
      if (props.subMappingActive) {
        mappingRequest.mappingId = props.subMappingMappingId;
      }
      store.dispatch('approveCompetitorMapping', mappingRequest)
        .then(() => {
          if (props.subMappingActive) {
            emit('subMappingMapped');
          } else {
            emit('closePopup', 'reload');
          }
          showNotificationMessage(competitor.mapping.extRef);
        })
        .catch(() => {
          console.log('mapping failed');
        });
    };

    const approveRegionMapping = () => {
      const region = props.suggestedMapping;
      const mappingRequest = {
        regionId: region.regionId,
        mappingId: props.selectedFeed.feed === HUDDLE ? region.mapping.mappingId : props.editData.row.mappingId,
      };
      if (props.subMappingActive) {
        mappingRequest.mappingId = props.subMappingMappingId;
      }
      store.dispatch('approveRegionMapping', mappingRequest)
        .then(() => {
          if (props.subMappingActive) {
            emit('subMappingMapped');
          } else {
            emit('closePopup', 'reload');
          }
          showNotificationMessage(region.mapping.extRef);
        })
        .catch(() => {
          console.log('mapping failed');
        });
    };

    const approveCompetitionMapping = () => {
      const competition = props.suggestedMapping;
      const mappingRequest = {
        competitionId: competition.competitionId,
        mappingId: props.selectedFeed.feed === HUDDLE ? competition.mapping.mappingId : props.editData.row.mappingId,
      };
      store.dispatch('approveCompetitionMapping', mappingRequest)
        .then(() => {
          if (props.subMappingActive) {
            emit('subMappingMapped');
          } else {
            emit('closePopup', 'reload');
          }
          showNotificationMessage(competition.mapping.extRef);
        })
        .catch(() => {
          console.log('mapping failed');
        });
    };

    const approvePlayerMapping = () => {
      const player = props.suggestedMapping;
      /* for mapping player in evets mapping we need row mappingId to send clicked feed and found player from other feed mappingId
      and for player mappings screen we need player.mapping.mappingId
      because on events we are finding players on different feeds and on player mapping we always find suggested player from clicked feed */
      const mappingIdForHuddle = props.editData.row?.extId ? props.editData.row.mappingId : player.mapping.mappingId;
      const mappingRequest = {
        playerId: player.playerId,
        mappingId: props.selectedFeed.feed === HUDDLE ? mappingIdForHuddle : props.editData.row.mappingId,
        sport: props.selectedSportLabel,
      };
      store.dispatch('approvePlayerMapping', mappingRequest)
        .then(() => {
          if (props.subMappingActive) {
            emit('subMappingMapped');
          } else {
            emit('closePopup', 'reload');
          }
          showNotificationMessage(player.mapping.extRef);
        })
        .catch(() => {
          console.log('mapping failed');
        });
    };

    const approveEventMapping = () => {
      const event = props.suggestedMapping;
      const eventMappingRequest = {
        eventId: event.eventId,
        mappingId: props.selectedFeed.feed === HUDDLE || mappingForEventList.value ? event.mapping.mappingId : props.editData.row.mappingId,
        competitorsFlipped: props.flippedEvents,
      };
      const suggestedCompetitorMappings = props.flippedEvents
        ? reverse(event.mapping.competitorMappings)
        : event.mapping.competitorMappings;
      const competitorsMappings = map(event.competitors, (comp, index) => ({
        competitorId: comp.competitorId,
        mappingId: (suggestedCompetitorMappings[index] || {}).mappingId,
      }));
      if (event.competitorMapping) {
        assign(eventMappingRequest, {
          competitorsMappings,
          competitorsFlipped: props.flippedEvents,
        });
      }
      const storeAction = event.competitorMapping
        ? 'approveEventCompetitorMapping'
        : 'approveEventMapping';

      store.dispatch(storeAction, eventMappingRequest)
        .then(() => {
          emit('closePopup', 'reload');
          showNotificationMessage(event.mapping.extRef);
        })
        .catch(() => {
          console.log('mapping failed');
        });
    };

    const approveMapping = () => {
      const {
        COMPETITORS_MAPPING,
        EVENTS_MAPPING,
        REGIONS_MAPPING,
        COMPETITIONS_MAPPING,
        PLAYERS_MAPPING,
      } = mappingTypes;

      switch (props.mappingType) {
      case COMPETITORS_MAPPING:
        approveCompetitorMapping();
        break;
      case EVENTS_MAPPING:
        approveEventMapping();
        break;
      case REGIONS_MAPPING:
        approveRegionMapping();
        break;
      case COMPETITIONS_MAPPING:
        approveCompetitionMapping();
        break;
      case PLAYERS_MAPPING:
        approvePlayerMapping();
        break;
      default:
        break;
      }
    };

    const handleAdditionalActions = (actionType) => {
      switch (actionType) {
      case VIEW_HISTORY:
        emit('viewHistory', props.suggestedMapping);
        break;
      case COPY_ID:
        if (props.mappingType === 'PLAYERS_MAPPING') {
          copyToClipboard(props.suggestedMapping.playerId);
        } else {
          copyToClipboard(props.suggestedMapping.competitorId);
        }
        copyIdMessageActive.value = true;
        const timeout = setTimeout(() => {
          copyIdMessageActive.value = false;
          setIsMappingHovered(false);
          clearTimeout(timeout);
        }, 3000);
        break;
      case SWITCH_TEAMS:
        emit('flipEvents');
        break;
      default:
        break;
      }
    };

    return {
      mapping,
      setIsMappingHovered,
      isMappingHovered,
      handleAdditionalActions,
      copyIdMessageActive,
      parentRef,
      setLastItemMenuPosition,
      menuTopPosition,
      approveMapping,
    };
  },
};
</script>

<style lang="scss">
.suggested-mappings-root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  position: relative;

  &.single-mapping {
    position: absolute;
    width: 100%;
  }

  &:hover {
    background-color: #FAFAFA;
    border: 1px solid #F0F0F0;
  }

  .suggested-mappings-details {
    display: flex;
    width: 60%;

    .percentage-chart { min-width: 60px; }

    .details-info {
      display: flex;
      width: calc(100% - 60px);
      flex-direction: column;
      justify-content: center;
      margin-left: 12px;
      padding-top: 2px;
      box-sizing: border-box;

      .details-info-name {
        margin-bottom: 6px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          margin: 0 4px;
          color: #A9A9A9;
        }
      }

      .details-info-date-id {
        color: #A9A9A9;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          color: #175FFF;
        }

        .info-id {
          text-decoration: underline;
          color: #A9A9A9;
        }
      }
    }
  }
  .suggested-mappings-actions {
    display: flex;
    justify-content: flex-end;

    .mapping-button {
      box-sizing: border-box;
      border-radius: 4px;
      display: flex;
      height: 33px;
      padding: 0 12px;
      margin-right: 20px;
      align-items: center;
      cursor: pointer;

      &.approve {
        background: #003C3C;
        color: #fff;
        margin-left: 16px;

        &:hover {
          background-color: rgba(#003C3C, 0.85);
        }
      }

      &:hover {
        background-color: rgba(200, 200, 200, 0.15);
      }
    }
  }

  .copy-confirmation {
    display: none;
    background-color: #191414;
    color: #FFF;
    font-size: 12px;
    font-family: 'Rubik';
    line-height: 14px;
    padding: 8px 12px;
    border-radius: 4px;
    position: absolute;
    right: 10px;
    bottom: -5px;

    &.active {
      display: inline-block;
    }
  }
}
</style>
