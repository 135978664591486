<template>
  <div class="mapping-container">
    <div class="mapping-container__header">
      <div class="mapping-container__title">
        {{ title }}
      </div>
      <div
        v-if="subtitle"
        class="mapping-container__subtitle"
      >
        {{ subtitle }}
      </div>
    </div>
    <div class="mapping-container__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.mapping-container {
  display: flex;
  flex-direction: column;
}

.mapping-container__header {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 12px;
}

.mapping-container__title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #191414;
}

.mapping-container__subtitle {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #A9A9A9;
}

.mapping-container__content {
  display: flex;
  flex-direction: column;
  height: 277px;
  overflow-y: auto;
  gap: 12px;
}
</style>
