<template>
  <div
    ref="rootElement"
    class="mapping-info-card-wrapper"
    @mouseenter="setHeaderVisibility(true)"
    @mouseleave="setHeaderVisibility(false)"
  >
    <div class="mapping-info-card">
      <div class="logo-block">
        <Icon
          :name="feed"
        />
      </div>
      <div class="huddle-info-details-wrapper">
        <div class="details-name">
          {{ title }}
        </div>
        <div
          v-if="subtitle"
          class="details-additional"
        >
          {{ subtitle }}
        </div>
      </div>
    </div>
    <div
      v-if="headerActionsVisible"
      class="suggested-mapping-huddle-actions"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    feed: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
  },
  setup() {
    const headerActionsVisible = ref(false);

    const setHeaderVisibility = (newHeaderActionsVisible) => {
      headerActionsVisible.value = newHeaderActionsVisible;
    };

    return {
      headerActionsVisible,
      setHeaderVisibility,
    };
  },
};
</script>

<style lang="scss" scoped>
.mapping-info-card-wrapper {
  width: 100%;
  min-height: 62px;
  max-height: 62px;
  background: #FAFAFA;
  border: 1px solid #F0F0F0;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;

  .mapping-info-card {
    display: flex;
  }
  .suggested-mapping-huddle-actions {
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 10px;

    .more-actions-btn .icon {
      stroke: #000;
      margin-right: 10px;
    }

    .copy-confirmation {
      display: none;
      min-width: 170px;
      text-align: center;
      background-color: #191414;
      color: #FFF;
      font-size: 12px;
      font-family: 'Rubik';
      line-height: 14px;
      padding: 8px 12px;
      border-radius: 4px;
      position: absolute;
      right: -30px;
      bottom: -10px;

      &.active {
        display: inline-block;
      }
    }
  }

  .logo-block {
    width: 52px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;

    }

    &.exact-mapping {
      img {
        height: 24px;
        width: 24px;
      }
    }
  }

  .huddle-info-details-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .details-name, .details-additional {
      height: 17px;
      line-height: 17px;
      color: #191414;

      span {
        color: #A9A9A9;
      }
    }

    .details-additional {
      color: #A9A9A9;
      margin-top: 4px;
    }
  }
}
</style>
