<template>
  <div
    ref="feedFilter"
    class="feed-filter-root"
  >
    <div
      class="selected-filter-feeds feed-filter"
      @click="
        () => {
          feedDropdownOpened = !feedDropdownOpened;
        }
      "
    >
      <div class="feed-filter-wrapper">
        <Icon
          :name="'huddle'"
          class="feed-icon"
          v-if="selectedFeed === 'huddle'"
        />
        <img
          src="/images/icons/betgenius.png"
          alt="betgenius"
          v-if="selectedFeed === 'betgenius'"
        >
        <img
          src="/images/icons/betradar.png"
          alt="betgenius"
          v-if="selectedFeed === 'betradar'"
        >
        <img
          src="/images/icons/lsports.png"
          alt="lsports"
          v-else-if="selectedFeed === 'lsports'"
        >
        {{ selectedFeed }}
      </div>
      <Icon
        :name="'chevron-down'"
        v-if="!feedDropdownOpened"
      />
      <Icon
        :name="'chevron-up'"
        v-else
      />
    </div>
    <div
      class="filter-dropdown-feeds feed"
      v-if="feedDropdownOpened"
    >
      <div
        :class="[
          'dropdown-option feed',
          { selected: option === selectedFeed },
        ]"
        v-for="(option, index) in feeds"
        :key="index"
        @click="setSelectedFeed(option)"
      >
        <Icon
          :name="'huddle'"
          v-if="option === 'huddle'"
        />
        <img
          src="/images/icons/betgenius.png"
          alt="betgenius"
          v-if="option === 'betgenius'"
        >
        <img
          src="/images/icons/betradar.png"
          alt="betradar"
          v-if="option === 'betradar'"
        >
        <img
          src="/images/icons/lsports.png"
          alt="lsports"
          v-if="option === 'lsports'"
        >
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import Icon from '@/components/common/Icon';

export default {
  props: {
    feeds: {
      required: true,
      type: Array,
      default: () => [],
    },
    selectedFeed: {
      required: true,
      type: String,
      default: '',
    },
  },
  components: { Icon },
  emits: ['feedSelected'],
  setup(props, { emit }) {
    const feedFilter = ref(false);
    const feedDropdownOpened = ref(false);

    const setSelectedFeed = (feed) => {
      emit('feedSelected', feed);
      feedDropdownOpened.value = false;
    };

    onClickOutside(feedFilter, () => {
      feedDropdownOpened.value = false;
    });

    return {
      feedFilter,
      feedDropdownOpened,
      setSelectedFeed,
    };
  },
};
</script>

<style lang="scss">
.feed-filter-root {
    height: 100%;
    position: relative;
    user-select: none;
    .selected-filter-feeds {
      border: 1px solid #DDDDDD;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      min-width: 165px;
      height: 100%;
      justify-content: center;
      cursor: pointer;

      .icon {
        margin-left: 4px;
        svg {
          stroke: #191414;
        }
      }

     &.feed-filter {
       margin: 0 12px 0 4px;
       justify-content: space-between;
       min-width: 136px;
       text-transform: capitalize;

       .feed-filter-wrapper {
         display: flex;
         align-items: center;
       }

       .icon {
         height: 14px;
         width: 14px;
         &.feed-icon {
           margin-left: 0;
           margin-right: 4px;
         }
       }
     }

     img {
       height: 14px;
       width: 14px;
       margin-right: 4px;
     }

    }
    .filter-dropdown-feeds {
      position: absolute;
      top: 40px;
      left: 5px;
      width: 90%;
      background-color: #fff;
      border: 1px solid #EBEBEB;
      box-sizing: border-box;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      z-index: $dropdownMenuZIndex;
      padding: 4px 0;

      .dropdown-option {
       height: 33px;
       display: flex;
       align-items: center;
       padding: 8px;
       box-sizing: border-box;
       cursor: pointer;
       color: #6D6D6D;

       .option-checkbox {
         width: 16px;
         height: 16px;
         border: 2px solid #CDCDCD;
         box-sizing: border-box;
         border-radius: 4px;
         display: flex;
         align-items: center;
         justify-content: center;
         margin-right: 10px;

         &.checked {
           background-color: #003C3C;
           border: 2px solid #003C3C;
         }

         .icon {
           width: 12px;
           height: 12px;

           svg {
             stroke: #fff;
           }
         }
       }

       img {
         height: 14px;
         width: 14px;
         margin-right: 4px;
       }

       &.time, &.feed {
         &.selected {
           background-color: rgba(0, 0, 0, 0.02);
         }
         &:hover {
           background-color: rgba(0, 0, 0, 0.03);
         }
         .icon {
           margin-right: 4px;
         }
       }

       &.feed {
         text-transform: capitalize;
       }
     }
   }
}
</style>
