<template>
  <div
    class="mapped-instance"
    @mouseenter="setActionsVisible(true)"
    @mouseleave="setActionsVisible(false)"
    @click.prevent="() => {}"
  >
    <div
      :class="[
        'mapped-instance__icon',
        { 'mapped-instance__icon--success': mappingType === 'EXACT_MATCH' },
        { 'mapped-instance__icon--warning': mappingType === 'FUZZY_MATCH' },
        { 'mapped-instance__icon--error': mappingType === 'NO_MATCH' },
      ]"
    >
      <Icon
        :name="iconName"
      />
    </div>
    <div class="mapped-instance__content">
      <div class="mapped-instance__info">
        <div
          v-if="title"
          class="mapped-instance__title"
        >
          {{ title }}
        </div>
        <div
          v-if="subtitle"
          class="mapped-instance__subtitle"
        >
          {{ subtitle }}
        </div>
      </div>
      <div
        v-if="actionable"
        class="mapped-instance__action"
      >
        <Button
          v-if="actionsVisible"
          :icon="buttonIcon"
          small
          fill
          @click="click"
        >
          {{ buttonLabel }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';

export default {
  components: {
    Icon,
    Button,
  },
  props: {
    mappingType: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    actionable: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    click: {
    },
  },
  setup(props, { emit }) {
    const actionsVisible = ref(false);

    const isExactMatch = computed(() => props.mappingType === 'EXACT_MATCH');
    const iconName = computed(() => (isExactMatch.value ? 'link-not-broken' : 'link-broken'));
    const buttonIcon = computed(() => (isExactMatch.value ? 'minus' : 'plus'));
    const buttonLabel = computed(() => (isExactMatch.value ? 'Unmap' : 'Map'));

    const setActionsVisible = (newActionsVisible) => {
      actionsVisible.value = newActionsVisible;
    };
    const click = () => {
      if (!props.actionable) return;
      emit('click');
    };

    return {
      actionsVisible,
      iconName,
      buttonIcon,
      buttonLabel,
      setActionsVisible,
      click,
    };
  },
};
</script>

<style lang="scss" scoped>
.mapped-instance {
  display: flex;
  align-items: center;
  gap: 12px;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 4px 12px;
}

.mapped-instance__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #000;
  stroke: #000;

  &.mapped-instance__icon--success {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #00BC57;
    border: 1px solid #00BC57;
    stroke: #00BC57;
  }

  &.mapped-instance__icon--warning {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #FFC531;
    border: 1px solid #FFC531;
    stroke: #FFC531;
  }

  &.mapped-instance__icon--error {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.92)), #FF2E2D;
    border: 1px solid #FF2E2D;
    stroke: #FF2E2D;
  }
}

.mapped-instance__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.mapped-instance__info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.mapped-instance__title {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #A9A9A9;
}

.mapped-instance__subtitle {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #191414;
}

.mapped-instance__action {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  stroke: #000;
}
</style>
